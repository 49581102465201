import React, { useEffect, useState } from 'react';
import { PATH } from './constants/path';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import Mqtt from './utils/Mqtt';
import moment from 'moment';
import { Textfit } from 'react-textfit';
import './styles/ticket.scss';

export default function Ticket() {
  let { restUrlId, ticketId } = useParams();

  const [mqttClient] = useState(new Mqtt());
  const [site, setSite] = useState(undefined);
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(0);
  const [tableType, setTableType] = useState('');
  const [startTagSequence, setStartTagSequence] = useState('0');
  const [endTagSequence, setEndTagSequence] = useState('0');

  const getQueue = (restUrlId, successCallback) => {
    const headers = { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' };
    const API_URL = process.env.REACT_APP_API_URL;
    const fetchUrl = `${API_URL}` + 'queue_batch/getQueue?restUrlId=' + restUrlId;

    fetch(fetchUrl, {
      method: 'GET',
      headers: headers
    }).then(response => {
      // console.log("response", response);
      return response.json();
    }).then((data) => {
      if (data.returnCode === 0) {
        if (data.payload) {
          successCallback(data.payload);
        }
      }
    }).catch((e) => {
      console.log(`Error: ${e}`);
    })
  }

  useEffect(() => {
    // ReactGA.set({ page: PATH.INDEX + "/" + restUrlId + "/" + ticketId });
    if (restUrlId) {
      getQueue(restUrlId, function getRestInfoSuccess(payload) {
        try {
          // console.log("payload", payload);
          setSite(payload);
          const tempTimestamp = Number(payload.lastUpdateTimestamp);
          if (!isNaN(tempTimestamp) && tempTimestamp > lastUpdateTimestamp) {
            setLastUpdateTimestamp(tempTimestamp);
          }
          if (payload.tableType) {
            setTableType(payload.tableType);
          }
          setStartTagSequence((payload.startTagSequence) ? '' + payload.startTagSequence : '0');
          setEndTagSequence((payload.endTagSequence) ? '' + payload.endTagSequence : '0');
          if (payload.tcName || payload.enName) {
            document.title = ((payload.tcName) ? payload.tcName + ' ' : '') + ((payload.enName) ? payload.enName : '');
            ReactGA.pageview(PATH.INDEX + restUrlId + "/");
          }
          // const mqtt = new Mqtt();
          // setMqttClient(mqtt);

          // console.log("mqttClient setReloadCallback");
          mqttClient.setReloadCallback(reload);
          reload();
        } catch (e) {
          console.log(`Error: ${e}`);
        }
      });
    }

    return () => {
      if (mqttClient) {
        // mqttClient.unSubscribe(this.props.ticket.restUrlId, this.props.ticket.timeSessionId);
        mqttClient.removeReloadCallback();
      }
    }
  }, [])

  // useEffect(() => {      
  //     if(mqttClient) {
  //         console.log("mqttClient has change");
  //         mqttClient.setReloadCallback(reload);
  //         reload();
  //     }  
  // },[mqttClient])

  const reload = () => {
    // console.log("reload");
    mqttClient.subscribe(restUrlId, 'BATCH/#');
    mqttClient.subscribe(restUrlId, 'queue');
    mqttClient.setMessageHandler(handleMessage);
  }

  // TAG_BATCH,A,000000,000100,1611893327649
  const handleMessage = (type, ticketTableType, from, to, timestamp) => {
    // console.log("type", type);
    // console.log("ticketTableType", ticketTableType);
    // console.log("from", from);
    // console.log("to", to);
    // console.log("timestamp", timestamp);
    if (ticketTableType !== 'A') {
      return;
    }

    switch (type) {
      case 'TAG_BATCH':
        const tempTimestamp = Number(timestamp);
        if (moment(tempTimestamp).isSame(moment(), 'day')) {
          if (!isNaN(tempTimestamp) && tempTimestamp > lastUpdateTimestamp) {
            setLastUpdateTimestamp(tempTimestamp);
          }
          setTableType(ticketTableType);
          setStartTagSequence(from);
          setEndTagSequence(to);
        }
        break;
      default:
    }
  }

  const getDisplayTagSequence = () => {
    if (site && site.tableType) {
      return tableType + padZero(startTagSequence, 3) + '~' + tableType + padZero(endTagSequence, 3);
    } else {
      return startTagSequence + '~' + endTagSequence;
    }
  }

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new CustomEvent('resize'));
    }, 500)
  }, [])


  const padZero = (number, length) => {
    var str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  // console.log("currentTimeStamp", moment(currentTimeStamp).format('DD/MM/YYYY HH:mm:sss'));
  // console.log("sectionStartTimestamp", moment(site.sectionStartTimestamp).format('DD/MM/YYYY HH:mm:sss'));
  // console.log("sectionEndTimestamp", moment(site.sectionEndTimestamp).format('DD/MM/YYYY HH:mm:sss'));
  return (
    <div className="ticket-wrapper">
      {
        site &&
        <>
          <div className="header">
            <div className="bar-top"></div>
            <div className="bar-bottom"></div>
          </div>
          <div className="sub-header">
            {/* {
              site.messageCode === 2 &&
              <div className="tag-wrapper">
                <div className="title">開放時間 Opening hours:</div>
                <div className="tag calling">
                  <span className="tag-number">{moment(site.sectionStartTimestamp).format('HH:mm')}</span>
                  <span> ~ </span>
                  <span className="tag-number">{moment(site.sectionEndTimestamp).format('HH:mm')}</span>
                </div>
              </div>
            } */}
            {
              site.messageCode !== 2 &&
              <div className="tag-wrapper">
                <div className="clock">叫號時間 Calling Time: <span>{lastUpdateTimestamp ? moment(lastUpdateTimestamp).format('HH:mm') : ""} </span></div>
                <div className="title">
                  <span className="tag-label">現時叫號</span><br></br> <span className="tag-label">Calling No.</span>
                </div>
                <div className="tag calling">
                  <Textfit
                    key={tableType + startTagSequence + '~' + endTagSequence}
                    className="tag-number"
                    mode="single">
                    {getDisplayTagSequence()}
                  </Textfit>
                  <img className="broadcast" src={process.env.PUBLIC_URL + "/broadcast.png"} alt="broadcast" />
                </div>
              </div>
            }
          </div>
          {
            (site.tcRejectReason && site.enRejectReason) &&
            <div className="reject-reason">
              <div>{site.tcRejectReason}</div>
              <div>{site.enRejectReason}</div>
            </div>
          }
          <div className="content">
            {
              site.cosImageUrl && <img className="logo" src={site.cosImageUrl} alt="banner" />
            }
            {
              site.tcName && site.enName ?
                <>
                  <span className="name">{site.tcName}</span>
                  <span className="name">{site.enName}</span>
                  {
                    site.termsAndConditions && <div className="remark-wrapper" dangerouslySetInnerHTML={{__html: site.termsAndConditions}}></div>
                  }
                </>
                :
                <div className="tag-wrapper">
                  <div className="title">沒有此活動</div>
                  <div className="title">Event does not exist</div>
                </div>
            }
          </div>
        </>
      }
    </div>
  );
}