import mqtt from 'mqtt/dist/mqtt';
import moment from 'moment';

let mqttInstance = null;

export default class Mqtt {
  constructor() {
    if (!mqttInstance) {
      if (this.reloadCountDown) {
        clearInterval(this.reloadCountDown);
      }
      this.mqttHost = `${process.env.REACT_APP_MQTT_HOST}`;
      this.mqttOptions = { username: `${process.env.REACT_APP_MQTT_USERNAME}`, password: `${process.env.REACT_APP_MQTT_PASSWORD}` }
      this.topicPrefix = `${process.env.REACT_APP_MQTT_TOPIC_PREFIX}`;
      this.mqttLastTimestamp = 0;
      this.firstInitTimestamp = Date.now();
      // this.mqttLastTimestamp = Date.now();
      this.mqttCheckTime = 60000;
      this.mqttReinitTime = 300000;
      // this.mqttReinitTime = 60000;
      this.subscribeTopicList = [];
      this.reloadCallback = undefined;

      const _this = this;

      // this.runInterval();
      this.reloadCountDown = setInterval(function countDown() {
        _this.runInterval();
      }, this.mqttCheckTime);

      mqttInstance = this;
    }

    return mqttInstance;
  }

  setUpMqtt() {
    // console.log('setUpMqtt');
    this.client && this.client.end();
    const client = mqtt.connect(this.mqttHost, this.mqttOptions);
    this.client = client;
  }

  runInterval() {
    const currentTimeStamp = Date.now();
    // console.log("runInterval currentTimeStamp", moment(currentTimeStamp).format('DD/MM/YYYY HH:mm:sss'));
    // console.log("runInterval this.mqttLastTimestamp", moment(this.mqttLastTimestamp).format('DD/MM/YYYY HH:mm:sss'));
    // console.log("runInterval currentTimeStamp - this.mqttLastTimestamp", currentTimeStamp - this.mqttLastTimestamp);
    if ((currentTimeStamp - this.mqttLastTimestamp) >= this.mqttReinitTime) {
      // console.log('mqtt reinit...');
      this.setUpMqtt();
      if (this.reloadCallback) {
        this.reloadCallback();
      }
      this.updateMqttTimestamp(currentTimeStamp);
    } else {
      console.log('mqtt not reinit');
    }
  }

  updateMqttTimestamp(timestamp) {
    // console.log("updateMqttTimestamp", moment(timestamp).format('DD/MM/YYYY HH:mm:sss'));
    this.mqttLastTimestamp = timestamp;
  }

  setMessageHandler(handler) {
    if (this.client) {
      const _this = this;
      // console.log('---setMessageHandler---');
      // 30179,A,000000,000100,1611893327649
      this.client.on('message', function handleMsg(topic, message) {
        // console.log('received message', message.toString());
        const messageItems = message.toString().split(',');
        const type = messageItems[0];
        // console.log("received message type", type);
        // console.log("received message timestamp", moment(timestamp).format('DD/MM/YYYY HH:mm:sss'));
        // console.log('_this.mqttLastTimestamp', moment(_this.mqttLastTimestamp).format('DD/MM/YYYY HH:mm:sss'));
        // console.log('_this.firstInitTimestamp', moment(_this.firstInitTimestamp).format('DD/MM/YYYY HH:mm:sss'));
        switch (type) {
          case 'TAG_BATCH':
            // if (timestamp >= _this.mqttLastTimestamp) {
            handler(messageItems[0], messageItems[1], messageItems[2], messageItems[3], messageItems[4]);
            // console.log('updateMqttTimestamp yes timestamp', timestamp);
            // console.log('updateMqttTimestamp yes _this.mqttLastTimestamp', moment(_this.mqttLastTimestamp).format('DD/MM/YYYY HH:mm:sss'));
            _this.updateMqttTimestamp(Number(messageItems[4]));
            // } else {
            // console.log('updateMqttTimestamp no _this.mqttLastTimestamp', moment(_this.mqttLastTimestamp).format('DD/MM/YYYY HH:mm:sss'));
            // }
            break;
          // case 'QUEUE_STATUS_BATCH':
          case 'SESSION_UPDATE':
            if (Number(messageItems[3]) >= _this.firstInitTimestamp) {
              // console.log('SESSION_UPDATE reload');
              window.location.reload();
            } else {
              // console.log('updateMqttTimestamp no _this.mqttLastTimestamp', moment(_this.mqttLastTimestamp).format('DD/MM/YYYY HH:mm:sss'));
            }
            break;
          // case 'SESSION_STOP':
          //   if (Number(messageItems[1]) >= _this.firstInitTimestamp) {
          //     console.log('SESSION_STOP reload');
          //     window.location.reload();
          //   }
          //   break;
          default:
        }
      });
    }
  }

  setReloadCallback(callback) {
    this.reloadCallback = callback;
  }

  removeReloadCallback() {
    this.reloadCallback = undefined;
  }

  subscribe(restUrlId, timeSessionId) {
    // const qos = 2;
    if (restUrlId && timeSessionId) {
      const newTopic = this.topicPrefix + '/' + restUrlId + '/' + timeSessionId;
      if (!this.subscribeTopicList.some(function findSameTopic(subscribedTopic) {
        return subscribedTopic === newTopic;
      })) {
        this.subscribeTopicList.push(newTopic);
      }
      if (this.client && this.client.connected) {
        // this.client.subscribe(newTopic,{qos:qos});
        this.client.subscribe(newTopic);
        // console.log('subcribe: ' + newTopic);
      } else if (this.client) {
        //  mqtt is not connected
        // console.log('mqtt is not connected');
        if (this.subscribeTopicList.length > 0) {
          const _this = this;
          this.client.on('connect', function connect() {
            _this.subscribeTopicList.map(function subscribeTopics(topic) {
              // _this.client.subscribe(topic,{qos:qos});
              // console.log('mqtt connected');
              _this.client.subscribe(topic);
              // console.log('subcribe: ' + topic);
            });
          })
        }
      } else {
        // client is not exist
        // console.log('client is not existed');
        this.setUpMqtt();
        if (this.subscribeTopicList.length > 0) {
          const _this = this;
          this.client.on('connect', function connect() {
            _this.subscribeTopicList.map(function subscribeTopics(topic) {
              // _this.client.subscribe(topic,{qos:qos});
              _this.client.subscribe(topic);
              // console.log('subcribe: ' + topic);
            });
          })
        }
      }
    }
  }

  unSubscribe(restUrlId, timeSessionId) {
    if (this.client && restUrlId && timeSessionId) {
      const topic = this.topicPrefix + '/' + restUrlId + '/' + timeSessionId;
      this.client.unsubscribe(topic);
      // console.log('unsubcribe: ' + topic);
      this.subscribeTopicList = this.subscribeTopicList.filter(function filterTopic(unsubscribedTopic) {
        return unsubscribedTopic === topic;
      })
    }
  }

  end() {
    this.client && this.client.end();
    this.subscribeTopicList.length = 0;
    this.reloadCountDown && clearInterval(this.reloadCountDown);
  }
}
