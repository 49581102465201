import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import ReactGA from 'react-ga';
// import PosList from './PosList';
import Ticket from './Ticket';

class App extends Component {
    constructor(props) {
      super(props);
      ReactGA.initialize(`${process.env.REACT_APP_CONFIG_GA_TRACKING_ID}`, {
        debug: false,
        titleCase: false
      });
      this.state = { showSettings: false };
    }
    
    render() {
        return (
            <Router basename={ process.env.PUBLIC_URL }>
                <Switch>
                    {/* <Route exact path="/">
                        <PosList />
                    </Route> */}
                    <Route exact path="/:restUrlId">
                        <Ticket />
                    </Route>
                    <Route exact path="/:restUrlId/:ticketId">
                        <Ticket />
                    </Route>
                </Switch>
            </Router>
        );
    }
}

export default App;